<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-row>
        <el-col :span="6">
          <div class="demo-input-suffix">
            <el-input placeholder="请输入需要查询的诊疗理疗的名称" v-model="searchdrug" @input="searchtreatmentInput" @blur="searchtreatmentblur">
                <template slot="prepend">名称</template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="without-outline flex aligncenter justifyend">
            <el-button class="mr20" type="primary" @click="addnewtreatment">新增项目</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--添加-->
    <el-table
      highlight-current-row
      :cell-style="{padding:'10px','text-align':'center'}"
      class="drugdata"
      style="width: 100%; margin-bottom: 20px;font-size: 12px;"
      :header-cell-style="{background:'#f5f5f5','text-align':'center'}"
      :data="items"
      row-key="_id"
      border
    >
      <el-table-column type="index" width="60"></el-table-column>
      <el-table-column prop="fullName" label="名称"> </el-table-column>
      <el-table-column prop="packageUnit" label="单位"></el-table-column>
      <el-table-column prop="sellprice" label="销售价格"> </el-table-column>
      <el-table-column prop="Creator" label="编辑"></el-table-column>
      <el-table-column prop="updatedAt" label="编辑时间" width="150" :formatter="formatTime"></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button v-debounce size="small" @click="handleedit(scope.row)">编辑</el-button>
          <el-button v-debounce size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="新增项目" :visible.sync="drugdataFormVisible" :close-on-click-modal="false">
      <el-form :model="drugdataForm" :rules="drugdatarules" ref="drugdataForm" label-width="100px" class="drugdataForm">
        <el-form-item class="drugname" label="名称" prop="fullName">
            <el-input v-model="drugdataForm.fullName"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
            <el-select v-model="drugdataForm.type" placeholder="类型">
                <el-option
                    v-for="item in goodstypeoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="规格" prop="packageUnit">
            <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">如该项目为次数，<br/>则填入"次"</div>
                <el-select v-model="drugdataForm.packageUnit" clearable filterable placeholder="请选择">
                    <el-option
                        v-for="item in unitelement"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="销售单价" prop="sellprice">
            <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">输入该项目的销售单价<br/></div>
                <el-input-number v-model="drugdataForm.sellprice" controls-position="right"></el-input-number>
            </el-tooltip>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="drugdataFormVisible = false">取 消</el-button>
        <el-button type="primary" v-if="!form._id" @click="addtreatment(drugdataForm)">添 加</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import unitsData from '@/utils/unit';
import unitelement from '@/utils/unitelement';
import excel from "@/utils/excel";
import XLSX from "xlsx";
export default {
  data() {
    return {
      dialogTableVisible: false,
      editdialogFormVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      drugdataFormVisible: false,
      currentPage: 1, // 当前页码
      pageSize: 20, // 每页数据量
      total: 0, // 总数据量
      items: [],
      drugdataForm:{},
      timeslot:"",
      search: "",
      searchdrug: "",
      searchdate: "",
      units:unitsData,
      unitelement:unitelement,
      searchsupplier: "",
      starttoend:[],
      form: {
        _id:"",
        name:"",
        manufacturer:"",
        drugtype:"",
        stock:"",
        specification:"",
        unit:"",
        productionDate:"",
        expirationDate:"",
        approvalNumber:"",
        supplier:"",
        purchasePrice:"",
        salePrice:"",
        inventorynumber:"",
        inventoryDate:"",
      },
      editform: {
        _id: "",
        name: "",
        rolename: "",
        des: "",
      },
      checkoptions: [],
      tableColumns: [], // 表格表头数据
      options: [{
          value: '处方',
          label: '处方'
        }, {
          value: '非处方',
          label: '非处方'
      }],
      goodstypeoptions: [{
        value: 4,
        label: '治疗'
      }, {
        value: 5,
        label: '理疗'
      }],
      drugdatarules: {
        type: [
            { required: true, message: '请选择商品类型', trigger: 'change' }
        ],
        fullName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
        ],
        packageUnit: [
            { required: true, message: '请选择单位，或者输入', trigger: 'change' }
        ],
        sellprice:[
            { type: 'number', required: true, message: '请输入销售单价', trigger: 'blur' }
        ]
      },
      //所有键的值
      columnHeader:[],

      // 导入的excel的数据
      excelData:[],

      // 表格显示列
      checkboxTableColumn:[],

      //表格数据
      tableData:[],

      // 当前分页
      upcurrentPage:1,

      // 每页显示数量
      uppageSize:10,

      // 数据总数
      uptotal:0
    };
  },
  computed: {},
  methods: {
    debounce(func, delay) {
      clearTimeout(this.timer);
      this.timer = setTimeout(func, delay);
    },
    //防抖
    searchtreatmentInput() {
      this.currentPage = 1,
      this.pageSize = 20,
      this.debounce(this.searchtreatment, 500);
    },
    searchtreatmentblur() {
      if(this.searchdrug === ''){
        this.loadItems();
      }
    },
    searchtreatment(){
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        search:this.searchdrug
      };
      this.$api.goods.searchtreatment(data).then((res) => {
        if(res.data.code === 200){
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        }else{
          this.items = [];
        }
      });
    },
    btnClick() {
      document.querySelector(".input-file").click();
    },
    resetting() {
      this.searchdate = ''
      this.loadItems();
    },
    handleedit(data) {
      this.drugdataFormVisible = true;
      this.drugdataForm = data
    },
    handleDelete(data) {
      this.$api.goods.deletetreatment(data).then((res) => {
        if(res.data.code === 200){
            this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.loadItems();
        }else{
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    addnewtreatment(){
      this.drugdataFormVisible = true;
      this.drugdataForm = {}
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    addtreatment(data){
      this.$api.goods.addtreatment(data).then((res) => {
        if(res.data.code === 200){
          this.drugdataFormVisible = false
          this.loadItems();
          this.$message({
          message: res.data.msg,
          type: "success",
        });
        }else{
          this.$message({
          message: res.data.msg,
          type: "warning",
        });
        }
      });
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$api.goods.alltreatment(data).then((res) => {
        if(res.data.code === 200){
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        }else{
          this.items = []
        }
      });
    },
    formatTime(row,column){
        let data = row[column.property]
        let dtime = new Date(data)
        const year = dtime.getFullYear()
        let month = dtime.getMonth() + 1
        if (month < 10) {
            month = '0' + month
        }
        let day = dtime.getDate()
        if (day < 10) {
            day = '0' + day
        }
        let hour = dtime.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = dtime.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        let second = dtime.getSeconds()
        if (second < 10) {
            second = '0' + second
        }
        return year+ '-' + month+ '-' + day
      }
  },
  created() {
    this.loadItems();
  },
};
</script>
<style>
.drugname .el-input__inner {
  width:100%;
}
.without-outline .el-input__inner {
  width: 380px;
}
.drugdata .el-table__body-wrapper{
  min-height: 550px;
}
.w250{
  width: 250px;
}
.w120{
  width: 120px;
}
.flex{
  display: flex;
}
.justifyend{
  justify-content: end;
}
.aligncenter{
  align-items: center;
}
button:focus {
    outline: none;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
.mr20{
  margin-left: 20px;
}
</style>
